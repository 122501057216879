@keyframes swipe {
    from {
      width: 100%;
    }
    to {
      width: 0%;
    }
  }
  
  .swipe-animation {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #2FACE0; /* Same as the page background color */
    z-index: 1000; /* Keep it above other elements */
    animation: swipe 2s forwards; /* Adjust the duration (2s) as needed */
  }
  